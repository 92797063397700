import React from 'react';

import { Image, Paragraph, ExternalLink } from 'components';

import arrow from './assets/arrow.svg';
import twitter from './assets/twitter.svg';
import linkedIn from './assets/linkedin.svg';

import styles from './styles.module.scss';

const Footer = ({
  openBecomeASponsorModal,
  openBecomeASpeakerModal,
  openBecomeAPartnerModal
}) => (
  <div className={styles.container}>
    <div className={styles.content}>
      <div className={styles.left}>
        <div className={styles.logo}>connect</div>

        <Paragraph className={styles.copyright}>
          Copyright © 2020 Connect powered by Unissu. All rights reserved.
        </Paragraph>

        <div
          className={styles.backToTop}
          onClick={() =>
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
          }>
          <img src={arrow} alt="arrow pointing up" /> Back to Top
        </div>
      </div>

      <div className={styles.right}>
        <div className={styles.getInTouch}>
          Get in touch with us regarding Speaking, Sponsorship or Partnership
        </div>

        <a className={styles.phone} href="tel:08004480141">
          08004480141
        </a>

        <div className={styles.socialNetworks}>
          <ExternalLink
            href="https://twitter.com/unissu_global"
            className={styles.socialNetwork}>
            <Image src={twitter} alt="twitter" />
          </ExternalLink>
          <ExternalLink
            href="https://www.linkedin.com/company/unissu/"
            className={styles.socialNetwork}>
            <Image src={linkedIn} alt="linkedin" />
          </ExternalLink>
        </div>
      </div>
    </div>
  </div>
);

export default Footer;
