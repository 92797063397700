import React, { useRef } from 'react';

import _ from 'lodash';

import { paths } from 'routes';

import { SEO, Layout, TypeformEmbed } from 'components';

import {
  JoinUs,
  Trends,
  Header,
  Quotes,
  Sponsors,
  Locations,
  Statistics,
  ProductDescription,
  Footer
} from './components';
import {
  SPEAKER_TYPEFORM_URL,
  SPONSOR_TYPEFORM_URL,
  PARTNER_TYPEFORM_URL
} from './constants';
import ProductDataSection from './components/ProductDescription/DataInsighSection';

import './styles.scss';

const HomePage = () => {
  const speakerTypeform = useRef(null);
  const sponsorTypeform = useRef(null);
  const partnerTypeform = useRef(null);

  const openTypeform = ref => {
    const typeform = _.get(ref, 'current.typeform');

    if (typeform) {
      typeform.open();
    }
  };

  const openBecomeASpeakerModal = () => openTypeform(speakerTypeform);
  const openBecomeASponsorModal = () => openTypeform(sponsorTypeform);
  const openBecomeAPartnerModal = () => openTypeform(partnerTypeform);

  return (
    <React.Fragment>
      <Layout
        page={paths.home}
        openBecomeASponsorModal={openBecomeASponsorModal}
        openBecomeASpeakerModal={openBecomeASpeakerModal}
        openBecomeAPartnerModal={openBecomeAPartnerModal}>
        <SEO />
        <Header openBecomeASpeakerModal={openBecomeASpeakerModal} />
        <Sponsors />
        <Statistics />
        <ProductDescription
          openBecomeASpeakerModal={openBecomeASpeakerModal}
          openBecomeAPartnerModal={openBecomeAPartnerModal}
        />
        <Locations openBecomeAPartnerModal={openBecomeAPartnerModal} />
        <ProductDataSection openBecomeAPartnerModal={openBecomeAPartnerModal} />
        <Trends openBecomeAPartnerModal={openBecomeAPartnerModal} />
        <JoinUs
          openBecomeASponsorModal={openBecomeASponsorModal}
          openBecomeAPartnerModal={openBecomeAPartnerModal}
        />
        <Quotes openBecomeASpeakerModal={openBecomeASpeakerModal} />
        <Footer
          openBecomeASponsorModal={openBecomeASponsorModal}
          openBecomeASpeakerModal={openBecomeASpeakerModal}
          openBecomeAPartnerModal={openBecomeAPartnerModal}
        />
      </Layout>
      <TypeformEmbed url={SPEAKER_TYPEFORM_URL} ref={speakerTypeform} />
      <TypeformEmbed url={SPONSOR_TYPEFORM_URL} ref={sponsorTypeform} />
      <TypeformEmbed url={PARTNER_TYPEFORM_URL} ref={partnerTypeform} />
    </React.Fragment>
  );
};

export default HomePage;
