const path = require('path');

const routes = {
  home: {
    path: '/',
    component: path.resolve(__dirname, '../pages/Home/index.js')
  }
};

// Same keys as 'routes', but the value is only the path.
const paths = Object.keys(routes).reduce((acc, route) => {
  acc[route] = routes[route].path;
  return acc;
}, {});

module.exports = { routes, paths };
