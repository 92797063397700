export const LOCATIONS = [
  {
    title: 'North America',
    items: [
      { text: 'Chicago' },
      { text: 'New York' },
      { text: 'San Francisco' },
      { text: 'Toronto' }
    ]
  },
  {
    title: 'South & Central America',
    items: [
      { text: 'Mexico City' },
      { text: 'Bogota' },
      { text: 'São Paulo' },
      { text: 'Buenos Aires' }
    ]
  },
  {
    title: 'Europe',
    items: [
      { text: 'London' },
      { text: 'Paris' },
      { text: 'Barcelona' },
      { text: 'Helsinki' },
      { text: 'Berlin' },
      { text: 'Vienna' },
      { text: 'Istanbul' },
      { text: 'Tel Aviv' },
      { text: 'Moscow' },
      { text: 'Budapest' }
    ]
  },
  {
    title: 'Africa',
    items: [{ text: 'Lagos' }, { text: 'Johannesburg' }]
  },
  {
    title: 'Asia',
    items: [
      { text: 'Beijing' },
      { text: 'Seoul' },
      { text: 'Tokyo' },
      { text: 'Hong Kong' },
      { text: 'Shanghai' },
      { text: 'Bangkok' },
      { text: 'Bangalore' },
      { text: 'Singapore' },
      { text: 'Jakarta' },
      { text: 'Dubai' }
    ]
  },
  {
    title: 'Australasia',
    items: [{ text: 'Sydney' }]
  }
];
