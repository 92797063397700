import React from 'react';

import { Waves, Button, Heading, Paragraph, Collapsible } from 'components';

import { LOCATIONS } from './constants';

import styles from './styles.module.scss';

const Locations = ({ openBecomeAPartnerModal }) => (
  <div className={styles.wrapper}>
    <Waves />
    <div className={styles.container}>
      <div className={styles.left}>
        <Heading size="medium" color="white" className={styles.title}>
          Filming Locations
        </Heading>
        <Paragraph color="white" size="medium" className={styles.description}>
          Connect and RE:Connect bring together networks of real estate, smart
          city and technology experts from around the world.
        </Paragraph>
        <Paragraph color="blue" size="small" className={styles.subDescription}>
          Local filming experiences and an open platform for creators delivers
          market leading thought leadership and connections.
        </Paragraph>
        <Button
          textColor="white"
          backgroundColor="blue"
          borderColor="blue"
          size="medium"
          onClick={openBecomeAPartnerModal}>
          Become a Partner
        </Button>
      </div>
      <div className={styles.right}>
        <Collapsible items={LOCATIONS} />
      </div>
    </div>
  </div>
);

export default Locations;
