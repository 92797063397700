import React from 'react';

import { Button } from 'components';

import styles from './styles.module.scss';

const JoinUs = ({ openBecomeAPartnerModal, openBecomeASponsorModal }) => (
  <div className={styles.container}>
    <div className={styles.item}>
      <div className={styles.title}>Sponsor</div>
      <div className={styles.description}>
        Present your brand as the global leader in the theme of your choice.
        Measure your ROI using our granular data analytics.
      </div>
      <Button
        borderColor="blue"
        backgroundColor="white"
        textColor="blue"
        size="medium"
        onClick={openBecomeASponsorModal}>
        Become a Sponsor
      </Button>
    </div>

    <div className={styles.item}>
      <div className={styles.title}>Partner</div>
      <div className={styles.description}>
        Join our unparalleled network of global delivery partners and be part of
        the exclusive club with the rights to record and distribute high quality
        thought leadership videos.
      </div>
      <Button
        borderColor="blue"
        backgroundColor="white"
        textColor="blue"
        size="medium"
        onClick={openBecomeAPartnerModal}>
        Become a Partner
      </Button>
    </div>
  </div>
);

export default JoinUs;
